<template>
  <div>
    <b-row>
      <b-col>
        <h4>Seleção de Empresas</h4>
        <fieldset class="font-weight-bold text-muted small">
          Selecione as empresas que deseja vincular neste filtro
        </fieldset>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <fieldset class="font-weight-bold text small">
          Empresas Vinculadas
        </fieldset>
        <vue-good-table
          ref="tabelaVinculadas"
          class="vinculo-grid"
          :columns="colunas"
          max-height="275px"
          :rows="valueInner.vinculadas"
          :fixed-header="false"
          @on-row-click="addDesvinculadas"
        >
          <div slot="emptystate">
            <p style="text-align:center; margin:0">
              Nenhum registro encontrado.
            </p>
          </div>
        </vue-good-table>
      </b-col>
      <b-col cols="6">
        <fieldset class="font-weight-bold text small">
          Empresas Desvinculadas
        </fieldset>
        <vue-good-table
          ref="tabelaNaoVinculadas"
          class="vinculo-grid"
          :columns="colunas"
          :rows="valueInner.naoVinculadas"
          :fixed-header="false"
          max-height="275px"
          @on-row-click="addVinculadas"
        >
          <div slot="emptystate">
            <p style="text-align:center; margin:0">
              Nenhum registro encontrado.
            </p>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
    <b-row class="mb-2" />
    <b-row>
      <b-col>
        <fieldset class="font-weight-bold text small mb-50">
          Total de empresas vinculadas
          {{ valueInner.vinculadas.length }}
        </fieldset>
        <b-btn
          variant="none"
          class="btn-danger mr-50 btn-sm"
          @click="clearVinculadas"
        >Desvincular Todas</b-btn>
      </b-col>
      <b-col class="text-left">
        <fieldset class="font-weight-bold text small mb-50">
          Total de empresas desvinculadas
          {{ valueInner.naoVinculadas.length }}
        </fieldset>
        <b-btn
          variant="none"
          class="btn-success btn-sm"
          @click="clearDesvinculadas"
        >Vincular Todas</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

// .vinculo-grid {
//   .footer__row-count__label{
//     display: none !important;
//   }

//   .footer__row-count__select {
//     display: none !important;
//   }

//   .vgt-inner-wrap {
//     box-shadow: none !important;
//   }
//   .vgt-table.bordered  {
//     font-size: 14px !important;
//   }
// }

.main-menu.menu-dark .navigation > li ul li > a {
  padding: 8px 10px 8px 12px;
}

.servico-badge {
  height: 1.2rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-left: 0.5rem;
}

.disable-table {
  pointer-events: none;
}
</style>
<script>
import {
  required,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import colunasPadrao from './shared/colunasPadrao'

export default {
  name: 'VinculoEmpresaFiltro',
  components: {
    VueGoodTable,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      valueInner: {},
      colunas: colunasPadrao,
      paginaOpt: {
        enabled: true,
        mode: 'records',
        perPage: 15,
        position: 'bottom',
        dropdownAllowAll: false,
        perPageDropdown: false,
        perPageDropdownEnabled: false,
        firstLabel: 'Primaira página',
        lastLabel: 'Última página',
        nextLabel: 'Próximo',
        prevLabel: 'Anterior',
      },
      filtroCustom: { id: 0 },
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueInner = value
      }
    },
  },
  created() {
    this.valueInner = this.value
  },
  methods: {
    addVinculadas(params) {
      this.valueInner.vinculadas.push(params.row)
      this.valueInner.naoVinculadas.splice(params.pageIndex, 1)
      this.$emit('input', this.valueInner)
    },

    addDesvinculadas(params) {
      this.valueInner.naoVinculadas.push(params.row)
      this.valueInner.vinculadas.splice(params.pageIndex, 1)
      this.$emit('input', this.valueInner)
    },

    clearDesvinculadas() {
      while (this.valueInner.naoVinculadas.length > 0) {
        this.valueInner.vinculadas.push(this.valueInner.naoVinculadas.pop())
      }
      this.$emit('input', this.valueInner)
    },

    clearVinculadas() {
      while (this.valueInner.vinculadas.length > 0) {
        this.valueInner.naoVinculadas.push(this.valueInner.vinculadas.pop())
      }
      this.$emit('input', this.valueInner)
    },
  },
}
</script>
